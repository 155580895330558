@import "variables.scss";
@import "form.scss";
@import "action.scss";
@import "breakpoints.scss";

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
