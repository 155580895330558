@import "core-scss/index.scss";

.mainContainer {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;

  .mainInnerContainer {
    position: relative;
  }

  h3 {
    color: $brand-black;
    display: inline;
    font-family: "Overpass", sans-serif;
    font-size: 40px;
    font-weight: 400;
    margin: 15px 0;
    text-align: left;

    @media screen and (max-width: $phone-wide) {
      font-size: 28px;
    }
  }

  .lottiePlane {
    position: absolute;
    right: -15px;
    top: 35px;

    @media screen and (max-width: $phone-wide) {
      right: -40px;
      top: 15px;
    }

    div {
      height: 150px;
      width: auto;
      z-index: 0;

      svg {
        transform: none;
      }
    }
  }

  .loginContainer {
    align-items: center;
    border: 1px solid $brand-black;
    display: flex;
    flex-flow: column;
    height: 400px;
    justify-content: center;
    margin-bottom: 10px;
    width: 500px;

    @media screen and (max-width: $phone-wide) {
      padding: 0 10px;
      width: 300px;

      form {
        input {
          width: 200px;
        }
      }
    }

    .innerLoginContainer {
      h6 {
        color: $brand-black;
        font-size: 22px;
        margin: 20px 0 0 0;
      }

      .loginButtonContainer {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
        margin: 0;
        width: 358px;

        @media screen and (max-width: $phone-wide) {
          flex-flow: column;
          width: 200px;
        }

        .forgotPasswordLink {
          color: $brand-light-blue;
          font-size: 14px;
          font-weight: 600;
          text-decoration: none;
          text-underline-offset: 3px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
