@import "core-scss/index.scss";

.landing {
  background-color: #f5f5f5;
  height: 100vh;
  text-align: center;
}

.landingHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;

  h1 {
    color: $brand-black;
    font-size: 40px;
    font-weight: 200;

    @media screen and (max-width: $phone-wide) {
      font-size: 32px;
    }
  }
}

.landingMainContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  @media screen and (max-width: $phone-wide) {
    flex-flow: column;
  }

  .landingMainImage {
    background: url("../../assets/IMG_6778.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    @media screen and (max-width: $phone-wide) {
      display: none;
    }
  }

  .landingLoginContainer {
    align-items: center;
    background-color: #f5f5f5;
    display: flex;
    flex-flow: column;
    height: 90vh;
    justify-content: center;
    width: 100%;

    @media screen and (max-height: 576px) {
      padding-bottom: 100px;
    }

    @media screen and (min-height: 1000px) {
      height: 100vh;
    }

    .landingLoginContent {
      margin-bottom: 50px;

      .globe {
        height: 340px;

        div {
          height: 400px;
        }
      }

      h3 {
        color: $brand-black;
        display: inline;
        font-family: "Overpass", sans-serif;
        font-size: 28px;
        font-weight: 400;
        margin: 15px 0;
        text-align: left;

        @media screen and (max-width: $tablet) {
          font-size: 24px;
        }
      }

      .loginButton {
        margin-top: 20px;

        a {
          background-color: $brand-blue;
          border: 1px solid $brand-blue;
          border-radius: 5px;
          color: $brand-white;
          font-size: 14px;
          font-weight: 600;
          padding: 8px 25px;
          text-decoration: none;
          transition: all 0.4s;

          &:hover {
            background-color: $brand-white;
            color: $brand-blue;
          }
        }
      }
    }
  }
}
