@import "core-scss/index.scss";

.footerContainer {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 100%;
}
