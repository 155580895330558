@import "core-scss/index.scss";

.profileContainer {
  min-height: 100vh;

  @media screen and (max-height: 414px) {
    height: auto;
  }

  .introContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1600px;
    width: 100%;

    .innerIntroContent {
      display: flex;
      flex: 1;
      justify-content: space-between;
      margin-top: 35px;

      @media screen and (max-width: $tablet) {
        flex-flow: column;
      }

      > div {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      .introPhoto {
        height: 400px;
        padding: 20px;

        @media screen and (max-width: $midpoint) {
          height: 300px;
        }

        @media screen and (max-width: $tablet) {
          padding: 20px 0;
        }

        @media screen and (max-width: $phone-wide) {
          height: 200px;
        }
      }

      .userInfo {
        align-items: flex-start;
        display: flex;
        flex-flow: column;
        justify-content: center;
        width: 100%;

        @media screen and (max-width: $tablet) {
          align-items: center;
          padding: 25px 0;
        }

        @media screen and (max-width: $phone) {
          align-items: flex-start;
          padding: 25px 0 25px 30px;
        }

        h3 {
          color: $brand-black;
          font-family: "Overpass", sans-serif;
          font-size: 40px;
          font-weight: 400;
          margin: 15px 0;
          text-align: left;

          @media screen and (max-width: $phone-wide) {
            font-size: 28px;
          }
        }

        h6 {
          color: $brand-black;
          display: inline;
          font-family: "Overpass", sans-serif;
          font-size: 22px;
          font-weight: 400;
          margin: 5px 0;
          text-align: left;
        }
      }
    }
  }

  .settingsContainer {
    align-items: center;
    display: flex;
    margin: 0 auto;
    max-width: 1600px;
    
    .toggleContainer {
      margin: 40px 0 0 100px;

      h3 {
        color: $brand-black;
        font-family: "Overpass", sans-serif;
        font-size: 40px;
        font-weight: 400;
        margin: 15px 0;
        text-align: left;

        @media screen and (max-width: $phone-wide) {
          font-size: 28px;
        }
      }

      .toggle {
        margin: 40px 0 0 70px;
  
        input[type="checkbox"]{
          display: none;
        }
        
        label {
          color: $brand-black;
          position: relative;
        }
        
        input[type="checkbox"] + label::before {
          background:$brand-light-blue;
          border: 1px solid $brand-light-blue;
          border-radius: 9px;
          content: ' ';
          display: block;
          height: 18px;
          left: -65px;
          position: absolute;
          top: 0px;
          width: 45px;
        }
        
        input[type="checkbox"] + label::after {
          background: $brand-light-blue;
          border: 1px solid $brand-light-blue; 
          border-radius: 50%;
          content: ' ';
          display: block;
          height: 30px; 
          left: -75px; 
          position: absolute;
          top: -6px;
          transition: all 0.3s ease-in;
          width: 30px; 
        }
        
        input[type="checkbox"]:checked + label::after {
          background: $brand-pink;
          border: 1px solid $brand-pink; 
          left: -40px; 
          transition: all 0.3s ease-in;
        }
      }
    }
  }
}
