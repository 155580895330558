@import "core-scss/index.scss";

.galleryContainer {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 50px auto;
  max-width: 1600px;
  width: 100%;

  .cardContainer {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .card {
      align-items: center;
      border-radius: 5px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 300px;
      margin: 20px;
      width: 280px;

      .removeContainer {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        width: 100%;

        .delete {
          background-color: transparent;
          border: none;
          cursor: pointer;
          margin: 5px 0 0 0;
          outline: transparent;

          .deleteLottie {
            div {
              height: 40px;
              width: 40px;
            }
          }
        }
      }

      h6 {
        color: $brand-black;
        display: inline;
        font-family: "Overpass", sans-serif;
        font-size: 16px;
        font-weight: 700;
        margin: 5px 0;
        text-align: center;
      }

      .typeInformation {
        height: 190px;
        width: 100%;

        h3 {
          color: $brand-black;
          font-family: "Overpass", sans-serif;
          font-size: 20px;
          font-weight: 900;
          margin: 10px 10px;
        }

        p {
          font-size: 14px;
          margin: 5px 10px;
        }

        a {
          font-size: 12px;
          margin: 5px 10px;
        }
      }
    }
  }
}
