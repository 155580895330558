@import "core-scss/index.scss";

.homepage {
  min-height: 100vh;

  @media screen and (max-height: 414px) {
    height: auto;
  }

  .introContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1600px;
    width: 100%;

    .innerIntroContent {
      display: flex;
      flex: 1;
      justify-content: space-between;
      margin-top: 35px;

      @media screen and (max-width: $tablet) {
        flex-flow: column;
      }

      > div {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      .introPhoto {
        height: 400px;
        padding: 20px;

        @media screen and (max-width: $midpoint) {
          height: 300px;
        }

        @media screen and (max-width: $tablet) {
          padding: 20px 0;
        }

        @media screen and (max-width: $phone-wide) {
          height: 200px;
        }
      }
    }
  }

  .calendarContainer {
    display: flex;
    justify-content: center;
  }
}
