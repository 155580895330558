.brandBlueButton {
  background-color: $brand-blue;
  border: 1px solid $brand-blue;
  border-radius: 5px;
  color: $brand-white;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
  padding: 10px 25px;
  transition: all 0.4s;

  &:hover {
    background-color: $brand-white;
    color: $brand-blue;
  }
}

.brandWhiteButton {
  background-color: $brand-white;
  border: 1px solid $brand-blue;
  border-radius: 5px;
  color: $brand-blue;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
  padding: 10px 25px;
  transition: all 0.4s;

  &:hover {
    background-color: $brand-blue;
    color: $brand-white;
  }
}

.cancelButton {
  background-color: $brand-red;
  border: 1px solid $brand-red;
  border-radius: 5px;
  color: $brand-white;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
  padding: 10px 25px;
  transition: all 0.4s;

  &:hover {
    background-color: $brand-white;
    color: $brand-red;
  }
}

.brandBlueLink {
  color: $brand-light-blue;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-underline-offset: 3px;

  &:hover {
    text-decoration: underline;
  }
}

.errorText {
  color: $danger;
  font-size: 11px;
  font-weight: 700;
}
