$brand-white: #f5f5f5;
$brand-black: #414a4c;
$brand-pink: #ffaca6;
$brand-red: #db7f79;
$brand-blue: #66a1de;
$brand-light-blue: #7dbbfa;
$brand-yellow: #ecf2bd;
$warning: #a38041;
$danger: #ad4b6ae5;
$success: #669c5d;
