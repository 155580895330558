@import "core-scss/index.scss";

.mainContainer {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 50px auto;
  max-width: 1600px;
  width: 100%;

  h6 {
    color: $brand-black;
    display: inline;
    font-family: "Overpass", sans-serif;
    font-size: 22px;
    font-weight: 400;
    margin: 15px 10px 0 0;
    text-align: left;

    @media screen and (max-width: $tablet) {
      margin-bottom: 10px;
    }
  }

  form {
    .datePickerLabels {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 10px;
      width: 100%;

      label {
        align-items: flex-end;
        color: $brand-black;
        display: flex;

        img {
          height: 20px;
          padding-right: 5px;
        }
      }
    }

    .datePicker {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      width: 100%;

      input:nth-child(1) {
        margin-right: 22px;
      }

      input {
        height: 40px;
        width: 90%;
      }
    }
  }

  .brandWhiteButton {
    margin-right: 10px;
  }

  .addButtonsContainer {
    .brandBlueButton {
      margin: 20px 30px;
    }
  }
}
