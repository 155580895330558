@import "core-scss/index.scss";

.mainContainer {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;

  h3 {
    color: $brand-black;
    font-family: "Overpass", sans-serif;
    font-size: 40px;
    font-weight: 400;
    margin: 15px 0;
    text-align: left;

    @media screen and (max-width: $phone-wide) {
      font-size: 22px;
    }
  }

  .forgotPasswordContainer {
    align-items: center;
    border: 1px solid $brand-black;
    display: flex;
    flex-flow: column;
    height: 400px;
    justify-content: center;
    margin-bottom: 10px;
    width: 500px;

    @media screen and (max-width: $phone-wide) {
      padding: 0 10px;
      width: 300px;

      form {
        input {
          width: 200px;
        }
      }
    }

    .innerForgotPasswordContainer {
      h6 {
        color: $brand-black;
        font-size: 22px;
        margin: 20px 0 0 0;

        @media screen and (max-width: $phone-wide) {
          font-size: 16px;
        }
      }

      .buttonContainer {
        display: flex;
        flex-flow: row;

        @media screen and (max-width: $phone-wide) {
          flex-flow: column;
        }

        .brandBlueButton {
          margin-right: 10px;
        }
      }
    }
  }
}
