@import "core-scss/index.scss";

.headerContainer {
  margin: 0 auto;
  max-width: 1600px;
  position: relative;
  width: 100%;

  @media screen and (max-width: $tablet) {
    display: flex;
    justify-content: space-between;
  }

  .mobile {
    background-color: transparent;
    border: transparent;
    display: none;
    float: right;
    height: 80px;

    @media screen and (max-width: $tablet) {
      display: flex;
    }

    .mobileLottie {
      div {
        height: 80px;
      }
    }
  }

  .innerHeaderContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .logo {
    height: 65px;
    padding: 10px 15px 5px 15px;
  }

  .headerLinks {
    display: flex;
    flex-flow: row;
    padding: 0 15px 5px 15px;

    @media screen and (max-width: $tablet) {
      display: none;
    }

    a {
      margin-left: 50px;

      @media screen and (max-width: $tablet) {
        margin-left: 0;
      }
    }

    .logoutButton {
      a {
        background-color: $brand-blue;
        border: 1px solid $brand-blue;
        border-radius: 5px;
        color: $brand-white;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 25px;
        text-decoration: none;
        transition: all 0.4s;

        &:hover {
          background-color: $brand-white;
          color: $brand-blue;
        }
      }
    }
  }

  .headerLinks.openDropdown {
    background-color: $brand-white;
    display: block;
    height: 90px;
    padding: 20px;
    position: absolute;
    top: 90px;
    width: 100%;

    .brandBlueLink {
      display: block;
      margin-bottom: 5px;
    }

    .logoutButton {
      margin-top: 30px;
    }
  }
}
