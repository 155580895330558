@import "core-scss/index.scss";

.adventurePage {
  min-height: 100vh;

  @media screen and (max-height: 414px) {
    height: auto;
  }

  .introContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1600px;
    width: 100%;

    .innerIntroContent {
      display: flex;
      flex: 1;
      justify-content: space-between;
      margin-top: 35px;

      @media screen and (max-width: $tablet) {
        flex-flow: column;
      }

      h6 {
        color: $brand-black;
        display: inline;
        font-family: "Overpass", sans-serif;
        font-size: 22px;
        font-weight: 400;
        margin: 5px 0;
        text-align: left;
      }

      .description {
        color: $brand-black;
        font-family: "Raleway", sans-serif;
        font-size: 18px;
        font-weight: 400;
        margin: 5px 0;
        max-width: 600px;
        text-align: left;
      }

      > div {
        align-items: center;
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: 20px;
        width: 100%;

        @media screen and (max-width: $phone-wide) {
          width: auto;
        }
      }

      .introPhoto {
        max-width: 400px;
        padding: 20px;

        @media screen and (max-width: $midpoint) {
          height: 300px;
        }

        @media screen and (max-width: $tablet) {
          padding: 20px 0;
        }

        @media screen and (max-width: $phone-wide) {
          height: auto;
          max-width: 250px;
        }
      }
    }
  }
}
