@import "core-scss/index.scss";

.mainContainer {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;

  h3 {
    color: $brand-black;
    font-family: "Overpass", sans-serif;
    font-size: 40px;
    font-weight: 400;
    margin: 5px 0;
    text-align: center;

    @media screen and (max-width: $phone-wide) {
      font-size: 22px;
    }
  }

  .loginLinkContainer {
    display: flex;
    justify-content: flex-end;
  }
}
